<template>
  <EwalletHistory />
</template>

<script>
import EwalletHistory from '@/components/EwalletHistory'

export default {
  components: {
    EwalletHistory,
  },
}
</script>
