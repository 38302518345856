<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div style="width: 100%" v-if="isSearch">
          <a-tabs>
            <a-tab-pane tab="Result" />
            <div slot="tabBarExtraContent" :style="{ cursor: 'pointer' }" @click="handleCloseTabSearch">
              <a-icon type="close" />
            </div>
          </a-tabs>
        </div>
        <!-- <a-form :form="form" @submit.prevent="handleSearch" v-if="!isSearch">
          <div class="row">
            <div class="col mt-2">
              <a-form-item>
                <a-input-search
                  placeholder="Order Number"
                  v-decorator="[
                    'search'
                  ]"
                />
              </a-form-item>
            </div>
            <div class="col-md-auto mt-1">
              <div>
                <a-form-item>
                  <a-range-picker v-decorator="['validity', rangeConfig]" />
                </a-form-item>
              </div>
            </div>
            <div class="col col-lg-1 mt-1">
              <a-form-item>
                <a-button type="primary" html-type="submit">
                  Search
                </a-button>
              </a-form-item>
            </div>
          </div>
        </a-form> -->
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 mb-3 mt-3">
        <a-card>
          <div class="d-flex justify-content-between">
            <div>
              <div class="text-muted"> Total Saldo E-wallet</div>
              <div class="ewallet font-weight-bold"> {{ commission.ewallet | currency }} </div>
            </div>
            <div>
              <a-button
                type="primary"
                size="small"
                @click="() => $router.push({ name: 'withdraw.request' })"
              >
                {{ $t('account.withdrawEwallet') }}
              </a-button>
            </div>
          </div>
          <a-tabs />
          <div class="d-flex justify-content-between">
            <div>
              <div class="text-muted" v-if="$store.getters['user/isDropship']"> {{ $t('dashboard.totalCommission') }} </div>
              <div class="text-muted" v-if="$store.getters['user/isDistributor']"> Total Penjualan </div>
            </div>
            <div>
              <div class="text-muted"> {{ !commission.commission ? 0 : commission.commission | currency }} </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>
              <div class="text-muted" v-if="$store.getters['user/isDropship']"> {{ $t('dashboard.onComingCommission') }} </div>
              <div class="text-muted" v-if="$store.getters['user/isDistributor']"> Penjualan Pada Transaksi Selanjutnya </div>
            </div>
            <div>
              <div class="text-muted"> {{ !commission.commission_will_received ? 0 : commission.commission_will_received | currency }} </div>
            </div>
          </div>
          <a-tabs />
          <div class="row d-flex" v-if="lastWithdraw">
            <div class="col-12 mb-3" style="font-size: 16px" v-if="$store.getters['user/isDropship']">
              {{ $t('account.lastWithdraw') }}
            </div>
            <div class="col-12 mb-3" style="font-size: 16px" v-if="$store.getters['user/isDistributor']">
              Penarikan Hasil Penjualan Terakhir
            </div>
            <div class="col-12 d-flex justify-content-between mb-1">
              <div class="text-muted"> {{ $t('account.date') }} </div>
              <div v-if="lastWithdraw && lastWithdraw.status_date"> {{ $moment(lastWithdraw.status_date * 1000).format('DD - MMMM - YYYY HH:mm:ss') }} </div>
            </div>
            <div class="col-12 d-flex justify-content-between mb-1">
              <div class="text-muted"> {{ $t('account.status') }} </div>
              <div> {{ lastWithdraw.status }} </div>
            </div>
            <div class="col-12 d-flex justify-content-between mb-1">
              <div class="text-muted"> Total </div>
              <div v-if="lastWithdraw && lastWithdraw.amount"> {{ lastWithdraw.amount | currency }} </div>
            </div>
          </div>
        </a-card>
      </div>
      <div class="col-lg-8 mb-3 mt-3">
        <a-card>
          <div class="d-flex justify-content-between">
            <div class="font-weight-bold"> Riwayat Ewallet </div>
            <!-- <div> Exsport Riwayat </div> -->
          </div>
          <a-tabs />
          <div class="d-flex justify-content-between flex-wrap">
            <div>
              <a-radio-group v-model="valueType" @change="onChangeType">
                <a-radio-button class="mr-2" :value="0">
                  {{ $t('account.all') }}
                </a-radio-button>
                <a-radio-button v-if="$store.getters['user/isDropship']" class="mr-2" :value="1">
                  {{ $t('account.commission') }}
                </a-radio-button>
                <a-radio-button v-if="$store.getters['user/isDistributor']" class="mr-2" :value="6">
                  Penjualan
                </a-radio-button>
                <a-radio-button class="mr-2" :value="3">
                  {{ $t('account.withdraw') }}
                </a-radio-button>
                <a-radio-button class="mr-2" :value="2">
                  {{ $t('account.tax') }}
                </a-radio-button>
                <a-radio-button class="mr-2" :value="7">
                  Biaya Transaksi
                </a-radio-button>
              </a-radio-group>
            </div>
            <div>
              <a-form :form="form" @submit.prevent="handleSearch" v-if="!isSearch">
                <a-form-item>
                  <a-range-picker @change="changeRangeDate" v-decorator="['validity', rangeConfig]" />
                </a-form-item>
              </a-form>
            </div>
          </div>
          <div v-if="!ewallet.length" class="d-flex justify-content-center align-center mb-3 mt-3">
            {{ $t('account.noData') }}
          </div>
          <template v-else>
            <div v-for="(item, index) in ewallet" :key="index">
              <div class="d-flex justify-content-between flex-wrap mb-3">
                <div>
                  <div class="text-bold"> {{ parseStatus(item.type_desc).name }} </div>
                  <div class="text-muted"> Tanggal: {{ $moment(item.date).format('DD - MMMM - YYYY') }} | Pukul: {{ $moment(item.date).format('HH:mm') }} </div>
                  <div class="text-muted"> {{ item.desc }} </div>
                </div>
                <div>
                  <div class="text-left text-sm-right" :style="{color: parseStatus(item.type_desc).color}">  {{parseStatus(item.type_desc).symbol}}{{ item.amount | currency }} </div>
                  <div class="text-muted"> Saldo: {{ item.balance | currency }} </div>
                </div>
              </div>
            </div>
          </template>
          <div class="d-flex justify-content-center pt-5 pb-2" v-if="!disableLoadMore">
            <a-button
              @click.prevent="loadMore"
              :loading="loading"
              size="large"
            >
              {{ $t('store.loadMore') }}
            </a-button>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  data: function () {
    return {
      isSearch: false,
      form: this.$form.createForm(this),
      rangeConfig: {
        rules: [{
          type: 'array',
          message: 'Please select range date time',
        }],
      },
      valueType: 0,
      page: 1,
      limit: 10,
      disableLoadMore: false,
      types: [],
    }
  },
  methods: {
    formateDate (value) {
      return moment(value).format('DD - MMMM - YYYY, HH:mm:ss')
    },
    handleSearch () {
      this.form.validateFieldsAndScroll((err, values) => {
        // eslint-disable-next-line
        if (err) return
        else if (!values.search && !values.validity) {
          this.$notification.error({
            message: 'Please Input Range Date',
          })
        }
        const { validity, search } = values
        const start_date = validity ? moment(validity[0]).format('YYYY-MM-DD hh:mm:ss') : ''
        const end_date = validity ? moment(validity[1]).format('YYYY-MM-DD hh:mm:ss') : ''
        const order_id = search || ''
        this.isSearch = true
        this.$store.dispatch('account/GETEWALLETHISTORY', { start_date, end_date, order_id })
        this.form.resetFields()
      })
    },
    onChangeType (e) {
      this.page = 1
      this.valueType = e.target.value
      this.types = !e.target.value ? [1, 2, 3, 4, 6, 7] : e.target.value === 3 ? [3, 4] : [e.target.value]
      this.form.resetFields()
      this.$store.dispatch('account/GETEWALLETHISTORY', { type: this.types, page: this.page, limit: this.limit })
        .then(({ disableButton }) => {
          this.disableLoadMore = disableButton
        })
    },
    parseStatus (type) {
      switch (type) {
        case 'seller_commission':
          return {
            name: this.$t('account.commission'),
            color: 'green',
            symbol: '+',
          }

        case 'seller_tax':
          return {
            name: this.$t('account.tax'),
            color: 'red',
            symbol: '-',
          }

        case 'seller_withdraw':
          return {
            name: this.$t('account.withdraw'),
            color: 'red',
            symbol: '-',
          }

        case 'seller_sales':
          return {
            name: 'Penjualan',
            color: 'green',
            symbol: '+',
          }

        case 'transaction_fee':
          return {
            name: 'Biaya Transaksi',
            color: 'red',
            symbol: '-',
          }

        case 'bank_transfer_fee':
          return {
            name: 'Transfer Fee',
            color: 'red',
            symbol: '-',
          }

        default:
          return {
            name: '',
            color: '',
            symbol: '',
          }
      }
    },
    changeRangeDate (date, dateString) {
      const start_date = moment(date[0]).format('YYYY-MM-DD hh:mm:ss')
      const end_date = moment(date[1]).format('YYYY-MM-DD hh:mm:ss')
      this.$store.dispatch('account/GETEWALLETHISTORY', { start_date, end_date })
      this.valueType = 0
    },
    loadMore () {
      this.page += 1
      this.types = !this.valueType ? [1, 2, 3, 4, 6, 7] : this.types === 3 ? [3, 4] : [this.types[0]]
      this.$store.dispatch('account/GETEWALLETHISTORY', { page: this.page, limit: this.limit, type: this.types })
        .then(({ disableButton }) => {
          this.disableLoadMore = disableButton
        })
    },
  },
  mounted () {
    this.$store.dispatch('account/GETEWALLETHISTORY', { page: this.page, limit: this.limit, type: [1, 2, 3, 4, 6, 7] })
    this.$store.dispatch('dashboard/getCommissionData')
  },
  computed: {
    ...mapState('account', {
      loading: state => state.loading,
      ewallet: state => state.ewalletHistory,
      lastWithdraw: state => state.lastWithdraw,
    }),
    ...mapState('dashboard', {
      commission: state => state.commission,
    }),
  },
}
</script>

<style lang="scss">
.ewallet {
  font-size: 1.6rem;
}
</style>
